import * as React from "react";
import type { PageProps } from "gatsby";
import BaseLayout from "../components/layout/BaseLayout";
import MyAccount from "../components/user/MyAccount";
import WithAuth from "../components/user/WithAuth";
import GtagUserSetter from "../components/user/GtagUserSetter";
import AmplitudeUserSetter from "../components/user/AmplitudeUserSetter";
import MatomoTrack from "../components/user/MatomoTrack";

const AccountRoute = (props: PageProps) => {
    return (
        <WithAuth>
            <GtagUserSetter/>
            <MatomoTrack />
            <AmplitudeUserSetter />
            <BaseLayout {...props}>
                <MyAccount/>
            </BaseLayout>
        </WithAuth>
    );
};

export const Head = () => (
  <>
    <title>Runic | My Account</title>
  </>
)


export default AccountRoute;
